import React,{useState,useEffect} from 'react'
import Content from '../../components/Content'
import TechStackPopup from '../tech-stack-popup'
import SocialIconLinks from '../social-links'
import {Link,navigate} from 'gatsby'
const BlogDetailView = ({contentComponent, id,title,content,prev_next}) =>{
    const windowGlobal = typeof window !== 'undefined' && window
    const techstackpopupintractedfun = ()=>{
        if(windowGlobal){
            if(windowGlobal.localStorage.getItem('techstackpopupintracted')){
                return `true`
            }else{
                return `false`
            }
           
        }
       
    }
    const [techstackpopup,setTechstackpopup] = useState(false)

    const [techstackpopupintracted,setTechstackpopupintracted] = useState(techstackpopupintractedfun)
    const PageContent = contentComponent || Content
    useEffect(()=>{
       if(!techstackpopup && techstackpopupintracted !== `true`){
        setTimeout(() => {
            setTechstackpopup(true)
        }, 40000);
       }
    },[techstackpopup])

    const handlePopup=(e)=>{
        if(e === 'open'){
            setTechstackpopup(true)
        }else if(e === 'close'){
            setTechstackpopup(false)
            setTechstackpopupintracted(`true`)
        }
    }
   
    return(
        <React.Fragment>
       
        <div className="blog-section">
        <div className="span12 widget-span widget-type-raw_jinja ">
        <Link to="/blog" className="back-list en-text" ><span>Back to list</span></Link>
        <div className="next-prev-case">
          <Link 
          to={prev_next[0].previous !== null ? prev_next[0].previous.fields.slug : null} 
          className={`next-list en-text ${prev_next[0].previous === null ? `disable`:``}`}>
            <span>Previous article</span></Link>
          <Link to={prev_next[0].next !== null ? prev_next[0].next.fields.slug : null} 
          className={`prev-list en-text ${prev_next[0].next === null ? `disable`:``}`} >
            <span>Next article</span></Link>
        </div>
        </div>
            <div className="blog-post-wrapper cell-wrapper">
               <div className="blog-section">
                    <div className="blog-post-wrapper cell-wrapper">
                        <div className="post-header blog-detail-header">
                            <h1>
                            <span id={id} className="hs_cos_wrapper hs_cos_wrapper_meta_field hs_cos_wrapper_type_text" >
                            {title}
                            </span>
                            </h1>
                        </div>
                       
                        <div className="post-body blog-detail-content">
                            <span id="hs_cos_wrapper_post_body" 
                            className="hs_cos_wrapper hs_cos_wrapper_meta_field hs_cos_wrapper_type_rich_text" >
                                
                                <PageContent className="content" content={content} />
                            </span>
                        </div>
                       
                    </div>
                </div>
               
                
            </div>
        </div>
        
        
        
  
        <TechStackPopup techstackpopup={techstackpopup} handlePopup={handlePopup}/>
        </React.Fragment>
    )
}

const SocialLinkIcon =()=>{
    return(
        <div className="row-fluid-wrapper row-depth-2 row-number-2 ">
            <div className="row-fluid ">
            <div className="span12 widget-span widget-type-cell share-post-page">

            <div className="row-fluid-wrapper row-depth-2 row-number-3 ">
            <div className="row-fluid ">
            <div className="span6 widget-span widget-type-raw_html post-body-share">
            <div className="cell-wrapper layout-widget-wrapper">
            <span id="hs_cos_wrapper_module_150844522374950" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_raw_html">
            <SocialIconLinks
            facebook={true}
            twitter={true}
            linkedin={true}
            gplus={true}/>
            </span>
            </div>
            </div>
            <div className="span6 widget-span widget-type-raw_html scroll-top">
            <div className="cell-wrapper layout-widget-wrapper">
            <span id="hs_cos_wrapper_module_150828268820052" 
            className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_raw_html">
            <a className="a-top" href="#top">Go to top</a>
            </span>
            </div>
            </div>
                </div>
            </div>

            </div>
            </div>
            </div>
    )
}

const RecommandedBlogView =()=>{
    return(
        <div className="row-fluid-wrapper row-depth-2 row-number-5 ">
            <div className="row-fluid ">
            <div className="span12 widget-span widget-type-cell related-post-section">
            <div className="row-fluid-wrapper row-depth-2 row-number-6 ">
            <div className="row-fluid ">
            <div className="span12 widget-span widget-type-raw_jinja ">
            <h3 className="related-post-title">You can also read</h3>
            <div className="post-listing">
            <div className="related-post-item ">
                    <div className="post-inner">
                    <div className="post-image-container">
                        <div className="hs-featured-image-wrapper">
                            <a href="#" className="hs-featured-image-link">
                                <span style={{backgroundImage:`url('https://www.jouret-development.com/hubfs/Blog%20images/JDSolutions_1_2%20Scrum%20Board.png')`}} className="hs-featured-image"></span>
                            </a>
                        </div>
            
                </div>
                                
                                
            <div className="post-body-container">
            <div className="post-header">
                <h3><a href="#" style={{textDecoration:`none`}}>Are You Really Scrum as a Project Manager?</a></h3>
                </div>
            
            </div>
            </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
    )
}
export {BlogDetailView,SocialLinkIcon,RecommandedBlogView}