import React from 'react'
import PropTypes from 'prop-types'
import {BlogDetailView,SocialLinkIcon,RecommandedBlogView} from '../components/blogdetail/blog-detail-view'
import { BlogDetailAside } from '../components/blogdetail/blog-detail-aside'


const BlogDetailTemplate = ({id,title,content,prev_next,contentComponent}) => {
  
    return (

      <div className="section">
          <div className=" white-bg blog-main-sections">

          <div className="pth-blog-body blog-section-padding">
          <div className="blog-main-section">
          <BlogDetailView contentComponent={contentComponent} id={id} title={title} content={content} prev_next={prev_next}/>
          {/* <SocialLinkIcon/>
          <RecommandedBlogView/> */}
          </div>
          <BlogDetailAside/>
          </div> 
          </div>
     </div>
     

    )
  }
  BlogDetailTemplate.propTypes = {
    content: PropTypes.node.isRequired,
    contentComponent: PropTypes.func,
    title: PropTypes.string
  }
  
  export default BlogDetailTemplate;