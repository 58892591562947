import React, { useState,useEffect,useRef } from 'react'
import BlogAgileSection from '../blog/agiel-section'
import {Link} from 'gatsby'
import peterimg from '../assets/images/peter.jpeg'
import {getLocationData} from '../location_data'
const BlogDetailAside=()=>{
    const [top, setTop] = useState(0)
    const wr = useRef(null)
    const wr1 = useRef(null)
    useEffect(()=>{
        if(window !== undefined && window.screen.width >= 768){
            window.addEventListener('scroll',watchScroll)
        return () =>{
            window.removeEventListener('scroll',watchScroll)
        }}
        },[top])


        
    let olds = []
    const watchScroll=(e)=>{      
        let d = wr.current
        let d1 = wr1.current
        olds.push(window.scrollY);

        if(olds.length > 0){
        if(olds[0] < window.scrollY){
        if(d.offsetTop - 95 < window.scrollY){
            if(top === 0){
                setTop(95)
            }
        }
        }else{
            if(d1.offsetTop - 95 > window.scrollY){
               
                setTop(0)
               
            }
        }
        }
       


    }
    return(
            <div className="blog-box-3 blog-sidebar-section">
            <div className="row-fluid-wrapper row-depth-2 row-number-7 ">
            <div className="row-fluid ">
            <div className="span12 widget-span widget-type-cell sidebar-wrapper">
                <TeachMe/>
                <Newsletter/>
                <BlogAgileSection/>
                <AboutPeterJouret/>
                <GetYourcards/>
                <div ref={wr1}>
                <div className="agl-box" ref={wr} style={{position:`${top !== 0 ? `fixed`:`relative`}`, transform:top !== 0 ? `translateY(150px)` : null,top:`0px`,maxWidth:`280px`,width:`100%`, transition:`all 0.5s`}}>
                <ScaleYourDevTeam/>
                </div>
                </div>
            </div>
            </div>
            </div>
            </div>
    )
}

const TeachMe=()=>{
    return(
        <div className="row-fluid-wrapper row-depth-2 row-number-8 ">
            <div className="row-fluid ">
            <div className="span12 widget-span widget-type-cell teach-me-section dark-bg margin-bottom-30">
            <div className="row-fluid-wrapper row-depth-2 row-number-9 ">
                <div className="row-fluid ">
                <div className="span12 widget-span widget-type-rich_text align-center">
                <div className="cell-wrapper layout-widget-wrapper">
                <h4>How to create <span>Proof Of Concept</span> without writing a single line of code?</h4>
                </div>
                </div>
                </div>
            </div>
            <div className="row-fluid-wrapper row-depth-2 row-number-10 ">
                <div className="row-fluid ">
                <div className="span12 widget-span widget-type-cta align-center white-cta">
                <div className="cell-wrapper layout-widget-wrapper">
                    <span>
                        <Link className="cta_button " to="/call-us">Teach me</Link>
                    </span>
                </div>
                </div>
                </div>
            </div>
            </div>
            </div>
        </div>
    )
}

const Newsletter=()=>{
    const windowGlobal = typeof window !== 'undefined' && window
    const [formdata,setFormdata]=useState({})
    const [emailupdated, setEmailupdated] = useState(false)
    const [subscribed,setSubscribed]= useState(false)
    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
      }
      const handleSubmit=(e)=>{
        const userrole = []
        const lifecycledata = []
        let phone = null
        let expert = null
        const form = e.target
        if(windowGlobal){
            if(windowGlobal.localStorage.getItem('roleData')){
                const t = JSON.parse(windowGlobal.localStorage.getItem('roleData'))
                t.forEach(el=>{
                    if(el.checked === true){
                        userrole.push(el.role)
                    }
                })
            }
             if(windowGlobal.localStorage.getItem('lifecycledata')){
                const s = JSON.parse(windowGlobal.localStorage.getItem('lifecycledata'))
                s.forEach(el=>{
                    if(el.checked === true){
                        lifecycledata.push(el.name)
                    }
                })
            }
           
             if(windowGlobal.localStorage.getItem('number')){
              phone = windowGlobal.localStorage.getItem('number')
            }
             if(windowGlobal.localStorage.getItem('expert_name')){
              expert = windowGlobal.localStorage.getItem('expert_name')
            }
        }
        fetch("/", {
            method: "post",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": form.getAttribute('name'), 
                            ...formdata,
                            "User_roles":userrole.length !== 0 ? `${userrole.join(", ")}` : null,
                            "Company_lifecycle":lifecycledata.length !== 0 ? `${lifecycledata.join(", ")}` : null,
                            "Phone":phone,
                            "Expertise":expert,
                            "Language":getLocationData('languages') !== null ? getLocationData('languages').split(',')[0] : null,
                            "Country":getLocationData('country_name'),
                            "Zip":getLocationData('zipcode'),
                            "User_country":getLocationData('country_name'),
                            "City":getLocationData('city'),
                            "Latitude":getLocationData('latitude'),
                            "Longitude":getLocationData('longitude'),
                            "State":getLocationData('state_prov'),
                         })
          })
            .then(() => setSubscribed(true))
            .catch(error => alert(error));
          e.preventDefault();
      }
      const formonChange =(e)=>{
        setFormdata({...formdata,[e.target.name]:e.target.value})
      }
      useEffect(()=>{
        if(window && !formdata[`subscription_email`] && !emailupdated){
            if(localStorage.getItem('email')){
                const sd=localStorage.getItem('email')
                setFormdata({...formdata,[`subscription_email`]:sd})
                setEmailupdated(true)
            }
        }
      },[subscribed])
    return(
        <div className="row-fluid-wrapper row-depth-2 row-number-11 ">
        <div className="row-fluid ">
        <div className="span12 widget-span widget-type-cell margin-bottom-30 dark-bg newsletter-section-sidebar">
        
        <div className="row-fluid-wrapper row-depth-2 row-number-12 ">
            <div className="row-fluid ">
        <div className="span12 widget-span widget-type-rich_text newsletter-heading">
        <div className="cell-wrapper layout-widget-wrapper">
        <h3>Newsletter</h3>
        <p>Signup for our newsletter to receive updates from us</p>
        </div>
               </div>
                </div>
        </div>
        
        <div className="row-fluid-wrapper row-depth-2 row-number-13 ">
        <div className="row-fluid ">
        <div className="span12 widget-span widget-type-blog_subscribe dark-bg newsletter-form-sidebar" >
        <div className="cell-wrapper layout-widget-wrapper">
            <h3 className="hs_cos_wrapper form-title"></h3>
        
                {!subscribed ? <div>
                    <form
                    name="newsletter_form"
                    method="post"
                    netlify-honeypot="bot-field"
                    data-netlify="true"
                    onSubmit={handleSubmit}
                    >
                    <input type="hidden" name="form-name" value="newsletter_form" />
                    <p hidden>
                    <label>
                        Don’t fill this out: <input name="bot-field" onChange={formonChange} />
                    </label>
                    </p>
                   
                   <div className="hs_email hs-email hs-fieldtype-text field hs-form-field">
                   <div className="input">
                    <input className="hs-input" name="subscription_email" type="email" value={formdata[`subscription_email`]} placeholder="Your email address" onChange={formonChange} required={true}/>
                   </div>
                    </div>
                    <p hidden>
                    <input name="User_roles" />
                    <input name="Company_lifecycle" />
                    <input name="Phone" />                  
                    <input name="Expertise" />                   
                    <input name="Language" />
                    <input name="Country" />
                    <input name="Zip" />
                    <input name="User_country" />
                    <input name="City" />
                    <input name="Latitude" />
                    <input name="Longitude" />
                    <input name="State" />
                    </p>
                    <div className="hs_submit hs-submit">
                    <div className="actions">
                        <input type="submit" value="Subscribe" className="hs-button primary large" />
                    </div>
                    </div>
                    </form>
                    </div>
                    :
                    <div className="submitted-message ">
                    Thanks for Subscribing!
                    </div>}
               </div>
                </div>
        </div>
   
        </div>
        
           </div>
            </div>
        </div>
    )
}


const AboutPeterJouret = () =>{
    return(
        <div className="row-fluid-wrapper row-depth-2 row-number-19 ">
        <div className="row-fluid ">
        <div className="span12 widget-span widget-type-cell sidebar-author margin-bottom-30">
        <div className="row-fluid-wrapper row-depth-2 row-number-20 ">
        <div className="row-fluid ">
        <div className="span12 widget-span widget-type-linked_image align-center">
        <div className="cell-wrapper layout-widget-wrapper">
            <img src={peterimg} 
            className="hs-image-widget cust745" alt="Peter Jouret" title="Peter Jouret"/>
        </div>
       </div>
        </div>
        </div>

        <div className="row-fluid-wrapper row-depth-2 row-number-21 ">
            <div className="row-fluid " style={{marginBottom:`5px`}}>
        <div className="span12 widget-span widget-type-rich_text ">
        <div className="cell-wrapper layout-widget-wrapper">
        <h3>About Peter Jouret</h3>
        <p>Peter is a Belgium Serial web enterpreneur who loves to create &amp; grow new tech opportunities that crosses his path during life into solid quality comapnies.</p>
        </div>
        </div>
        </div>
        </div>

    <div className="row-fluid-wrapper row-depth-2 row-number-22 ">
        <div className="row-fluid ">
    <div className="span12 widget-span widget-type-cta red-cta align-center" >
    <div className="cell-wrapper layout-widget-wrapper">

    <Link  className="cta_button " to="/call-us"  title="Schedule a call">Schedule a call</Link>
        
            </div>
        </div>
            </div>
    </div>

   </div>
    </div>
</div>
    )
}

const GetYourcards = ()=>{
    return(
        <div className="row-fluid-wrapper row-depth-2 row-number-23 get_cards">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-cell dark-bg free-planning margin-bottom-30">

<div className="row-fluid-wrapper row-depth-2 row-number-24 ">
    <div className="row-fluid ">
<div className="span12 widget-span widget-type-rich_text free-heading align-center" >
<div className="cell-wrapper layout-widget-wrapper">
<h1>Free</h1>
<p>planning poker cards</p>
</div>
       </div>
        </div>
</div>

<div className="row-fluid-wrapper row-depth-2 row-number-25 ">
    <div className="row-fluid ">
<div className="span12 widget-span widget-type-rich_text align-center free-description">
<div className="cell-wrapper layout-widget-wrapper">
<p>Start estimating your projects on a fun &amp; accurate way by using planning poker carrds</p>
</div>
       </div>
        </div>
</div>

<div className="row-fluid-wrapper row-depth-2 row-number-26 ">
    <div className="row-fluid ">
<div className="span12 widget-span widget-type-cta white-cta align-center" >
<div className="cell-wrapper layout-widget-wrapper">
            <Link id="cta_button_3320949_ee3cd15b-9b6f-46a1-9519-9135f562274f" 
            className="cta_button "
            to="/free-planning-poker-cards"
            title="Get your cards"
            >Get your cards</Link>
            </div>
       </div>
        </div>
</div>

   </div>
    </div>
</div>
    )
}

const ScaleYourDevTeam=()=>{
    return(
        <div className="row-fluid-wrapper row-depth-2 row-number-27 scale_team">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-cell scale-dev margin-bottom-30">

<div className="row-fluid-wrapper row-depth-2 row-number-28 ">
    <div className="row-fluid ">
<div className="span12 widget-span widget-type-rich_text align-center scale-dev-head">
<div className="cell-wrapper layout-widget-wrapper">
<h3>Scale your dev team</h3>
<p>while you focus on what matters:</p>
</div>
       </div>
        </div>
</div>

<div className="row-fluid-wrapper row-depth-2 row-number-29 ">
    <div className="row-fluid ">
<div className="span12 widget-span widget-type-rich_text align-center scale-dev-desc">
<div className="cell-wrapper layout-widget-wrapper">
<h3>New clients &amp; projects</h3>
</div>
       </div>
        </div>
</div>

<div className="row-fluid-wrapper row-depth-2 row-number-30 ">
    <div className="row-fluid ">
<div className="span12 widget-span widget-type-cta red-cta align-center">
<div className="cell-wrapper layout-widget-wrapper">

            <Link className="cta_button " to="/call-us" title="Learn more">Learn more</Link>
            </div>
       </div>
        </div>
</div>
   </div>
    </div>
</div>
    )
}
export {BlogDetailAside,TeachMe,Newsletter,AboutPeterJouret,GetYourcards,ScaleYourDevTeam};

