import React,{useState,useEffect} from 'react'
import Layout from '../layouts'
import { graphql, Link } from 'gatsby'
import BlogDetailTemplate from './blog-detail-template'
import { HTMLContent } from '../components/Content'
import Helmet from 'react-helmet'


const BlogDetail = ({data}) => {
const [page, setPage] = useState('blog blog-detail hs-blog-post formal')
const { markdownRemark: post } = data
  return (
      <Layout page={page}>
         <Helmet defer={false}>
         <title>{`${post.frontmatter.title} | JDSolutions`}</title>   
         <meta property="og:title" content={`${post.frontmatter.title} | JDSolutions`} />
        <meta property="og:type" content="blog" />
        {/* <meta property="og:image" content="http://ia.media-imdb.com/images/rock.jpg" />      */}
      </Helmet>
      {console.log('ddddd',post)}
      <BlogDetailTemplate 
      id={post.id}
      title={post.frontmatter.title}
      content={post.html}
      prev_next={data.allMarkdownRemark.edges.filter(el=>(el.node.id === data.markdownRemark.id))}
      contentComponent={HTMLContent}/>
    </Layout>
  )
}
export default BlogDetail



export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
      }
    }
    allMarkdownRemark(sort: {order: ASC, fields: [frontmatter___date]}, filter: {frontmatter: {templateKey: {eq: "blog-detail"}}}) {
      edges {
        node {
          id
          fields {
            slug
          }
        }
        previous {
          fields {
            slug
          }
        }
      next {
          fields {
            slug
          }
        }
      }
    }
  }
`
