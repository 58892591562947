import React from 'react'
import './style.scss'
const SocialIconLinks = ({facebook, twitter, linkedin, gplus,classname})=>{
    return(
        <ul className={classname}>
        {!facebook ? null : <li className="fb-share">
            <a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a>
            </li>}
        {!twitter ? null : <li className="tw-share">
            <a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a>
            </li>}
        {!linkedin ? null :<li className="lk-share">
            <a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
            </li>}
        {!gplus ? null : <li className="gp-share">
            <a href="#"><i className="fa fa-google-plus" aria-hidden="true"></i></a>
            </li>}
        </ul>
    )
}
export default SocialIconLinks