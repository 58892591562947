import React, { useEffect, useState } from 'react'
import agile from '../assets/images/agile.svg'
import {Link} from 'gatsby'
const BlogAgileSection =()=>{
    const [referer,setReferer] = useState(null)
    useEffect(()=>{
        if(window !== undefined){
             setReferer(window.btoa(document.location.pathname))
          
        }
    },[])
    return(
        <div className="row-fluid-wrapper row-depth-2 row-number-15 ">
        <div className="row-fluid ">
        <div className="span12 widget-span widget-type-cell dark-bg padding-36 dev-module margin-bottom-30">
        <div className="row-fluid-wrapper row-depth-2 row-number-16 ">
        <div className="row-fluid ">
        <div className="span12 widget-span widget-type-rich_text align-center">
        <div className="cell-wrapper layout-widget-wrapper">
        <div className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text">
        <h3>Agile, scrum, <span>development?</span></h3>
        </div>
        </div>
        </div>
        </div>
        </div>

        <div className="row-fluid-wrapper row-depth-2 row-number-17 ">
        <div className="row-fluid ">
        <div className="span12 widget-span widget-type-linked_image align-center margin-bottom-30">
        <div className="cell-wrapper layout-widget-wrapper">
        <span className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_linked_image">
            <img className="hs-image-widget " src={agile} alt='agile' />
        </span>
        </div>
        </div>
        </div>
        </div>

        <div className="row-fluid-wrapper row-depth-2 row-number-18 ">
        <div className="row-fluid ">
        <div className="span12 widget-span widget-type-cta align-center white-cta">
        <div className="cell-wrapper layout-widget-wrapper">
        <span id="hs_cos_wrapper_module_1508277892998109" 
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_cta" >
        <span className="hs-cta-wrapper" 
        id="hs-cta-wrapper-f2c4e947-0fbe-48c1-b56d-b220115d43ed">
            <span className="hs-cta-node hs-cta-f2c4e947-0fbe-48c1-b56d-b220115d43ed"
             id="hs-cta-f2c4e947-0fbe-48c1-b56d-b220115d43ed" >
                 <Link id="cta_button_3320949_fd4bdecb-604b-44e5-b892-3e82b1519bfb" 
                 className="cta_button " to={`/scrum-or-scrumbut?trackingCode=${referer}`}>Check Our Solutions</Link>
                 </span>
                 </span>
                 </span>
                 </div>
                </div>
                </div>
        </div>
        </div>
        </div>
        </div>
    )
}
export default BlogAgileSection;