import React,{useState,useEffect} from 'react'
import tools from '../assets/images/tools.svg'
import servers from '../assets/images/servers.svg'
import version_control from '../assets/images/version_control.svg'
import database from '../assets/images/databse.svg'
import backend from '../assets/images/backend.svg'
import frontend from '../assets/images/frontend_icon.svg'
import {getLocationData} from '../location_data'
import './style.scss'

const TechStackPopup = ({techstackpopup, handlePopup}) =>{
    const windowGlobal = typeof window !== 'undefined' && window
    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
      }
    const [activetechstack, setActivetechstack] = useState(null)
    const [techstackdata,setTechstackdata] = useState([
        {listname:'Frontend'
        ,listicon:frontend, 
        selected:false,
        listdata:[{itemname:'HTML',selected:false}, 
                {itemname:'HTML5',selected:false}, 
                {itemname:'Javascript',selected:false}, 
                {itemname:'TypeScript',selected:false}, 
                {itemname:'JQuery',selected:false}, 
                {itemname:'CSS3',selected:false}, 
                {itemname:'Actionscript',selected:false}, 
                {itemname:'Angular.js',selected:false}, 
                {itemname:'React',selected:false}, 
                {itemname:'Vue.js',selected:false}, 
                {itemname:'Bootstrap',selected:false}, 
                {itemname:'Material UI',selected:false}, 
                {itemname:'Foundation',selected:false}, 
                {itemname:'Semantic UI',selected:false}, 
                {itemname:'Materialize',selected:false},
                ]},
        {listname:'Backend',
        listicon:backend, 
        selected:false,
        listdata:[
                {itemname:'PHP',selected:false}, 
                {itemname:'.Net',selected:false}, 
                {itemname:'Ruby on Rails',selected:false}, 
                {itemname:'Angular2+',selected:false}, 
                {itemname:'Node.js',selected:false}, 
                {itemname:'Java',selected:false}, 
                {itemname:'Python',selected:false}, 
                {itemname:'SQL',selected:false},
            ]},
        {listname:'Database',
        listicon:database, 
        selected:false,
        listdata:[
                {itemname:'MySQL',selected:false},
                {itemname:'MongoDB',selected:false},
                {itemname:'CouchDB',selected:false},
                {itemname:'MS SQL',selected:false},
                ]},
        {listname:'Version Control',
        listicon:version_control, 
        selected:false,
        listdata:[
                {itemname:'Git',selected:false}, 
                {itemname:'Grunt',selected:false},
                {itemname:'Xdebug',selected:false}, 
                {itemname:'Subversion',selected:false}, 
                {itemname:'Webpack',selected:false},
                            ]},
        {listname:'Servers & Hosting',
        listicon:servers, 
        selected:false,
        listdata:[
                {itemname:'AWS',selected:false}, 
                {itemname:'Azure',selected:false},
                {itemname:'Apache',selected:false}, 
                {itemname:'Nginx',selected:false},
                {itemname:'Hostgator',selected:false},
                ]},
        {listname:'PM Tools',
        listicon:tools, 
        selected:false,
        listdata:[
                {itemname:'Youtrack',selected:false}, 
                {itemname:'Jira',selected:false}, 
                {itemname:'Basecamp',selected:false}, 
                {itemname:'Trello',selected:false}, 
                {itemname:'Redmine',selected:false}, 
                {itemname:'Teamwork',selected:false}, 
                {itemname:'Asana',selected:false}, 
                {itemname:'Pivotal Tracker',selected:false},
            ]}
    ])

    const handleSelect = (e) =>{
        if(Array.isArray(e.selecteditem)){
            let olddata = techstackdata
            olddata.forEach(dt =>{
                if(dt.listname === e.listname){
                    const status = !dt.selected
                    dt.selected = status
                    dt.listdata.forEach(ft=>{
                        ft.selected = status
                    })
                }
            })
            setTechstackdata([...olddata])
            
        }else if(Number.isInteger(e.selecteditem)){
            let olddata = techstackdata
            olddata.forEach(dt =>{
                if(dt.listname === e.listname){ 
                   dt.listdata[e.selecteditem].selected = !dt.listdata[e.selecteditem].selected
                }
            })
            setTechstackdata([...olddata])
        }
    }

    const handleFilteredData = () =>{
        let data = techstackdata
        let filtereddata = []
        const userrole = []
        const lifecycledata = []
        let email = null
        let phone = null
        let expert = null
        let frontend = []
        let backend = []
        let database = []
        let version_control = []
        let server_hosting = []
        let project_management = []
        if(windowGlobal){
            if(windowGlobal.localStorage.getItem('roleData')){
                const t = JSON.parse(windowGlobal.localStorage.getItem('roleData'))
                t.forEach(el=>{
                    if(el.checked === true){
                        userrole.push(el.role)
                    }
                })
            }
             if(windowGlobal.localStorage.getItem('lifecycledata')){
                const s = JSON.parse(windowGlobal.localStorage.getItem('lifecycledata'))
                s.forEach(el=>{
                    if(el.checked === true){
                        lifecycledata.push(el.name)
                    }
                })
            }
             if(windowGlobal.localStorage.getItem('email')){
              email = windowGlobal.localStorage.getItem('email')
            }
             if(windowGlobal.localStorage.getItem('number')){
              phone = windowGlobal.localStorage.getItem('number')
            }
             if(windowGlobal.localStorage.getItem('expert_name')){
              expert = windowGlobal.localStorage.getItem('expert_name')
            }
        }
        data.forEach((el,i) =>{
            const filtered = el.listdata.filter(element=> element.selected === true)
            if(i === 0) {
             frontend = filtered.map((elm) => elm.itemname)   
            }
            if(i === 1){
                backend = filtered.map((elm) => elm.itemname)    
            }
            if(i === 2){
                database = filtered.map((elm) => elm.itemname)   
            }
            if(i === 3){
                version_control = filtered.map((elm) => elm.itemname)   
            }
            if(i === 4){
                server_hosting = filtered.map((elm) => elm.itemname)   
            }
            if(i === 5){
                project_management = filtered.map((elm) => elm.itemname)   
            }
         
        })
        data.forEach(dt =>{
           if(dt.selected){
            filtereddata.push(dt.listname)
           }
        dt.listdata.forEach(el =>{
            if(el.selected === true){
                filtereddata.push(el.itemname)
            }
        })
       
    })
    if(filtereddata.length !== 0){ 
    fetch("/", {
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({"form-name": 'Techstack from data', 
                      "User_roles":userrole.length !== 0 ? `${userrole.join(", ")}` : null,
                      "Company_lifecycle":lifecycledata.length !== 0 ? `${lifecycledata.join(", ")}` : null,
                      "Stack-Frontend":frontend.length !==0 ? `${frontend.join(", ")}`: null,
                      "Stack-Backend":backend.length !==0 ? `${backend.join(", ")}`: null,
                      "Stack-Database":database.length !==0 ? `${database.join(", ")}`: null,
                      "Stack-Version_control":version_control.length !==0 ? `${version_control.join(", ")}`: null,
                      "Stack-Server_hosting":server_hosting.length !==0 ? `${server_hosting.join(", ")}`: null,
                      "Stack-Project_management":project_management.length !==0 ? `${project_management.join(", ")}`: null,
                      "Email":email,
                      "Phone":phone,
                      "Expertise":expert,
                      "Language":getLocationData('languages') !== null ? getLocationData('languages').split(',')[0] : null,
                      "Country":getLocationData('country_name'),
                      "Zip":getLocationData('zipcode'),
                      "User_country":getLocationData('country_name'),
                      "City":getLocationData('city'),
                      "Latitude":getLocationData('latitude'),
                      "Longitude":getLocationData('longitude'),
                      "State":getLocationData('state_prov'),
                    })
      })
        .then(() => {           
            windowGlobal.localStorage.setItem('techstackpopupintracted',`true`)
        })
        .catch(error => alert(error));  
    }
    handlePopup('close')
}
     
   const handleTechList=(e)=>{
       if(activetechstack === e){
        setActivetechstack(null)
       }else{
        setActivetechstack(e)
       }
   }    


    useEffect(()=>{
    },[techstackdata,activetechstack])
    return(
        <div className={`secondspop ${techstackpopup ? `ani-playthree` : null}`} style={{display:`${techstackpopup ? 'block':`none`}`}}>
             <form hidden
                    name="Techstack from data"
                    method="POST"
                    netlify-honeypot="bot-field"
                    data-netlify="true"
                    > 
                    <input type="hidden" name="form-name" value="Techstack from data" />
                    <p hidden>
                    <label>
                        Don’t fill this out: <input name="bot-field" />
                    </label>
                    </p>  
                    <input name="User_roles" />
                    <input name="Company_lifecycle" />
                    <input name="Stack-Frontend" /> 
                    <input name="Stack-Backend" /> 
                    <input name="Stack-Database" /> 
                    <input name="Stack-Version_control" /> 
                    <input name="Stack-Server_hosting" /> 
                    <input name="Stack-Project_management" /> 
                    <input name="Email" /> 
                    <input name="Phone" />                  
                    <input name="Expertise" />                   
                    <input name="Language" />
                    <input name="Country" />
                    <input name="Zip" />
                    <input name="User_country" />
                    <input name="City" />
                    <input name="Latitude" />
                    <input name="Longitude" />
                    <input name="State" />
               
                </form>                
        <div className="main-container step3">
        <h1 className="header-container">
        <span>Please</span>{' '}
        <span className="green-marked green-box">
        <b className="marked home-slide3">let us know</b>
        <span style={{width:`100%`}}>
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 587 111">
        <polygon fill="#66C293" points="2,0 587.2,2.2 567.2,111.6 0,105.8 "></polygon>
        </svg>
        </span>
        </span>{' '}
        <span>your</span>{' '}
        <span className="green-marked green-box delay-2">
        <b className="marked home-slide3">tech stack</b>
        <span style={{width:`100%`}}>
         <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 587 111">
        <polygon fill="#66C293" points="2,0 587.2,2.2 567.2,111.6 0,105.8 "></polygon>
        </svg>
        </span>
        </span>
        {' '}
        <span>and we make our content tech tailored to your business:</span>
        </h1>
        <i className="fa fa-times ripple" aria-hidden="true" onClick={()=>handlePopup('close')}/>
        <div className="lists-row">

        {techstackdata.map((el,i)=>{
            return(
            <div key={`tdata${i}`} className={`list-container ${el.listname.toLowerCase()}`}>
            <div className="list-title-block" onClick={()=>handleTechList(i)}>
            <img src={el.listicon} alt="frontend"/>
            <span className="list-title-block-text">{el.listname}</span>
            <i className={`fa fa-chevron-${activetechstack === i ? `up`:`down`}`} aria-hidden="true"></i>
            </div>
            <ul style={{display:activetechstack === i ? `block`:``}}>
            <li className={`list-title ${el.selected ? `li-active` : null}`} 
            onClick={()=> handleSelect({listname:el.listname,selecteditem:el.listdata})}>
                <span>{el.listname}</span>
                </li>
           {el.listdata.map((ldt,i)=>{
           return(<li key={`ldt${i}`} 
           className={`list-item-single ${ldt.selected ? `li-active` : null}`} 
           onClick={()=> handleSelect({listname:el.listname,selecteditem:i})}>
               <span>{ldt.itemname}</span>
               </li>)
           })}
            
           
            </ul>
            </div>
            )
        })}
        
        
        
        </div>
        <div className="main-buttons-container">
        <a href="#" onClick={handleFilteredData} className="main-button">Cool, let's continue reading</a>
        </div>
        </div>
        </div>
    )
}
export default TechStackPopup